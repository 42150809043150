const scrollToTopBtn = document.querySelector( '.e-div--on-click-scroll-wrapper .e-btn' );

if ( scrollToTopBtn ) {
  window.addEventListener( 'scroll', () => {
    const scrollPosition = window.scrollY;
    const documentHeight = document.documentElement.scrollHeight;
    const windowHeight = window.innerHeight;
  
    // Check if scrolled past 50% of the page
    if ( scrollPosition > ( documentHeight - windowHeight ) / 3 ) {
      scrollToTopBtn.classList.add( 'visible' );
    }
    else {
      scrollToTopBtn.classList.remove( 'visible' );
    }
  } );
  
  scrollToTopBtn.addEventListener( 'click', () => {
    window.scrollTo( { top: 0, behavior: 'smooth' } );
    scrollToTopBtn.classList.remove( 'visible' );
  } );
}

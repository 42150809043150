/* eslint-disable no-console */
class TableContent {
  constructor( section ) {
    this.selector = document.querySelector( section );
    if ( !this.selector ) return;

    this.tableOfContent = this.selector?.querySelector( '.e-div--table-of-content' );
    this.links = this.tableOfContent?.querySelectorAll( 'ul a' );
    this.contents = this.selector.querySelectorAll( '.wp-block-heading' );
    this.header = document.querySelector( 'header' );
    this.headerHeight = this.header.offsetHeight;
    this.contentPosition = [];
    this.createProgressBar();
    this.getContentPosition();
    this.bindTableContentClick();
    this.setTableContentSticky();
    this.handleScroll = this.handleScroll.bind( this );
    window.addEventListener( 'scroll', this.handleScroll );
    if ( window.screenY > 0 ) {
      this.handleScroll();
    }
  }

  handleScroll() {
    this.updateProgressBar();
    this.setActiveTableContent();
  }

  bindTableContentClick() {
    this.links?.forEach( link => {
      link.addEventListener( 'click', ( event ) => {
        let href = link.getAttribute( 'href' );
        if ( href.substring( 0, 1 ) === '#' ) {
          href = href.substring( 1 );
          event.preventDefault();
          this.scrollToSection( href );
        } else {
          console.log( location.origin );
          console.log( new URL( href ).origin );
          const isExternalURL = ( href ) => new URL( href ).origin !== location.origin;
          isExternalURL ? window.open( href, '_blank' ) : window.open( href, '_self' );
        }
      } );
    } );
  }

  getContentPosition() {
    this.contents.forEach( content => {
      const id = content.getAttribute( 'id' );
      const positions = content.getBoundingClientRect().top + window.scrollY - ( this.headerHeight + 40 ) ;
      if( id === null ) return;
      this.contentPosition.push( {
        id: id,
        position: positions
      } );
    } );
  }

  scrollToSection( href ) {
    this.contentPosition.forEach( array => {
      if ( array.id !== href ) return;
      this.windowScroller( array.position );
    } );
  }

  createProgressBar() {
    const div = document.createElement( 'div' );
    div.classList.add( 'progress-bar' );
    this.header.append( div );
  }

  setActiveTableContent() {
    const scrollTop = window.scrollY + this.header.offsetHeight + 10;
    let activeId = '';
    for ( const item of this.contentPosition ) {
      if ( scrollTop >= item.position ) {
        activeId = item.id;
      }
    }

    this.links.forEach( link => {
      const href = link.getAttribute( 'href' ).substring( 1 );
      if ( href === activeId ) {
        link.classList.add( 'active' );
      } else {
        link.classList.remove( 'active' );
      }
    } );
  }

  updateProgressBar() {
    const scrollPos = window.scrollY;
    const windowHeight = window.innerHeight;
    const documentHeight = this.selector.offsetHeight + this.selector.nextElementSibling.offsetHeight;
    const progress = ( scrollPos / ( documentHeight - windowHeight ) ) * 100;
    const progressBar = document.querySelector( '.progress-bar' );
    document.documentElement.style.setProperty( '--scroll-progress', progress + '%' );
    if ( progress > 0 ) {
      progressBar.classList.add( 'progress-bar-active' );
    } else {
      progressBar.classList.remove( 'progress-bar-active' );
    }
  }

  setTableContentSticky() {
    const tableContentHeight = this.tableOfContent?.getBoundingClientRect().height;
    const windowHeight = window.innerHeight - ( this.headerHeight + 40 );
    if( tableContentHeight > windowHeight ) {
      this.tableOfContent.classList.add( 'remove-sticky' );
    }
  }

  windowScroller( scrollPosition ) {
    window.scrollTo( {
      top: scrollPosition,
      behavior: 'smooth'
    } );
  }
}

const tableContent = new TableContent( '.e-stn--blog-inner-progress-bar' );
document.addEventListener( 'DOMContentLoaded', tableContent );
const observer = new MutationObserver( ( mutations, observer ) => {
  const targetElement = document.querySelector( '.hs-form-private' );
  if ( targetElement ) {
    new TableContent( '.e-stn--blog-inner-progress-bar' ); //NOSONAR
    observer.disconnect();
  }
} );

observer.observe( document.body, { childList: true, subtree: true } );

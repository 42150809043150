class HistoryCard {
  constructor( section ) {
    this.section = section;
    this.tabSlider = this.section.querySelector( '.tab-slider' );
    this.tabEl = this.section.querySelector( '.tabs' );
    this.tabs = this.tabEl.querySelectorAll( 'li' );

    this.prevActiveTabId = 0;
    this.tabs[this.prevActiveTabId].classList.add( 'active' );
  }

  init() {
    this.responsiveNavPanel();
    this.sliderChangeEvent();
    this.navTabClick();
  }

  navTabClick() {
    this.tabs.forEach( ( tab, index ) => {
      tab.addEventListener( 'click', () => {
        const contentSwiper = this.tabSlider.querySelector( '.swiper' ).swiper;
        contentSwiper.slideTo( index, 300, true );
      } );
    } );
  }
  
  responsiveNavPanel() {
    const timeline = document.querySelectorAll( '.e-stn--history-card .tabs' )[0];
    const items = document.querySelectorAll( '.e-stn--history-card .tabs li' );

    items.forEach( item => {
      item.addEventListener( 'click', function() {
            
        // Remove active class from all items
        items.forEach( i => i.classList.remove( 'active' ) );

        // Add active class to the clicked item
        item.classList.add( 'active' );

        // Calculate the scroll position to center the clicked item
        const itemHeight = item.offsetHeight;
        const itemOffsetTop = item.offsetTop;
        const containerHeight = document.querySelectorAll( '.e-stn--history-card .tabs-wrapper' )[0].clientHeight;
        const scrollPosition = itemOffsetTop - ( containerHeight / 2 ) + ( itemHeight / 2 );

        // Smoothly scroll the timeline
        timeline.parentElement.scrollTo( {
          top: scrollPosition,
          behavior: 'smooth'
        } );
      } );
    } );
  }

  sliderChangeEvent() {
    const contentSwiper = this.tabSlider.querySelector( '.swiper' ).swiper;
    contentSwiper.on( 'slideChange', () => {
      const activeIndex = contentSwiper.activeIndex;
      this.tabs[activeIndex].classList.add( 'active' );
      this.tabs[activeIndex].click();
      this.prevActiveTabId = activeIndex;
    } );
  }
}

const cardSlider = document.querySelector( '.e-stn--history-card' );
if ( cardSlider ) {
  const historyCard = new HistoryCard( cardSlider );
  document.addEventListener( 'DOMContentLoaded', () => historyCard.init() );
}
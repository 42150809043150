function appendVerticalTabCont() {
  // Select the elements
  const wrapperAccordion = document.querySelector( '.e-stn--vertical-tabs .e-acc__itms-wrpr' );
  const accordionFixCont = document.querySelector( '.e-stn--vertical-tabs .col-sm-12 > .e-div' );

  if ( wrapperAccordion && accordionFixCont ) {
    // Remove .e-div from its current parent before appending
    if ( accordionFixCont.parentNode ) {
      accordionFixCont.parentNode.removeChild( accordionFixCont );
    }
    // Insert .e-div as the first child of .e-acc__itms-wrpr
    wrapperAccordion.insertBefore( accordionFixCont, wrapperAccordion.firstChild );
  }
}

appendVerticalTabCont();
/* eslint-disable no-unused-vars */
import SimpleBar from 'simplebar';
import 'simplebar/dist/simplebar.css';

const wrapper = document.querySelector( '.video-list' );
if ( wrapper ) {
  const simpleBar = new SimpleBar( wrapper, { // NOSONAR
    autoHide: false,
    forceVisible: 'y',
    scrollbarWidth: 'auto',
    scrollbarStyle: 'dark',
  } );
}
import { slideDown, slideUp } from './common';

document.addEventListener( 'DOMContentLoaded', function() {
  let windowWidth = window.innerWidth;
  const duration = 600;
  const filterWrappers = document.querySelectorAll( '.e-col--side-bar-filters > .e-div .e-div' );
  const firstFilterWrapper = document.querySelector( '.e-col--side-bar-filters > .e-div .e-div' );

  if ( !filterWrappers.length ) {
    return;
  }
  if ( windowWidth > 991 ) {
    slideDown( firstFilterWrapper?.querySelector( '.facetwp-type-checkboxes' ), duration );
    firstFilterWrapper.classList.add( 'e-div--active' );
  }
  
  filterWrappers.forEach( function ( filterWrapper ) {
    const filterTitle = filterWrapper.querySelector( 'p' );
    const filter = filterWrapper.querySelector( '.facetwp-type-checkboxes' );
    filterTitle.addEventListener( 'click', function () {
      let isExpand = filterWrapper.classList.contains( 'e-div--active' );
      if( isExpand ) {
        filterWrapper.classList.remove( 'e-div--active' );
        slideUp( filter, duration );
      } else {
        filterWrapper.classList.add( 'e-div--active' );
        slideDown( filter, duration );
      }
    } );
  } );
} );
function handleTabClick( event, index, h4Elements, imgElements ) {
  imgElements.forEach( img => img.classList.remove( 'active' ) );
  imgElements[index].classList.add( 'active' );
  h4Elements.forEach( h4 => h4.classList.remove( 'active' ) );
  event.currentTarget.classList.add( 'active' );
}

const tabSection = document.querySelector( '.e-stn--tab-section' );

if ( tabSection ) {
  const h4Elements = tabSection.querySelectorAll( '.left-side h4' );
  const imgElements = tabSection.querySelectorAll( '.right-side .e-div--content-wrapper' );

  h4Elements.forEach( ( h4, index ) => {
    h4.addEventListener( 'click', ( event ) => handleTabClick( event, index, h4Elements, imgElements ) );
  } );

  if ( h4Elements.length > 0 && imgElements.length > 0 ) {
    h4Elements[0].classList.add( 'active' );
    imgElements[0].classList.add( 'active' );
  }
}

document.addEventListener( 'DOMContentLoaded', function () {
  const sliderSections = document.querySelectorAll( '.e-tbs--children-tab-slider' );
  sliderSections.forEach( slider => {
    const slides = slider.querySelectorAll( '.e-tbs__nav .swiper-slide' );
    const contentSlides = slider.querySelector( '.e-tbs__content' );
    slides.forEach( ( slide, i ) => {
      const title = slide.querySelector( 'h4' ).cloneNode( true );
      const thisSlide = contentSlides.querySelectorAll( '.e-div--bottom-content-wrapper' )[i];
      thisSlide.prepend( title );
    } );
  } );
} );


const tabsSectionScroll = {
  menuLinks: document.querySelectorAll( '.e-stn--customer-stories-inner-tabs a' ),
  sections: document.querySelectorAll(
    '.e-stn--customer-stories-inner-content'
  ),

  /**
   * Initialize tabs and tab events
   * @returns void
   */
  init: () => {
    if ( tabsSectionScroll.menuLinks.length === 0 ) return;
    tabsSectionScroll.menuLinks.forEach( link => {
      link.setAttribute( 'data-text', link.textContent );
      link.addEventListener( 'click', ( e ) => {
        e.preventDefault();
        const targetId = link.getAttribute( 'href' ).substring( 1 );
        tabsSectionScroll.scrollToSection( targetId );
      } );
    } );

    const observerOptions = {
      root: null,
      rootMargin: window.innerWidth > 2500 ? '-15% 0px -85%' : '-30% 0px -70%',
      threshold: 0
    };

    const observer = new IntersectionObserver( tabsSectionScroll.observerCallback, observerOptions );
    tabsSectionScroll.sections.forEach( section => observer.observe( section ) );

    const initialHash = window.location.hash.substring( 1 );
    if ( initialHash ) {
      tabsSectionScroll.scrollToSection( initialHash );
    }
  },

  /**
   * Scroll to triggered section
   * @param {String} targetId 
   */
  scrollToSection: ( targetId ) => {
    const targetSection = document.getElementById( targetId );
    if ( targetSection ) {
      const offsetTop = targetSection.getBoundingClientRect().top + window.scrollY - 114;
      window.scrollTo( {
        top: offsetTop,
        behavior: 'smooth'
      } );
    }
  },

  /**
   * Observer callback
   * @param {Element} entries 
   */
  observerCallback: ( entries ) => {
    const visibleSections = entries
      .filter( entry => entry.isIntersecting )
      .sort( ( a, b ) => a.target.offsetTop - b.target.offsetTop );

    if ( visibleSections.length > 0 ) {
      const firstVisibleSection = visibleSections[0].target;
      tabsSectionScroll.updateActiveLink( firstVisibleSection.id );
    } else {
      tabsSectionScroll.handleNoVisibleSections();
    }
  },

  /**
   * Activate triggered item
   * @param {String} targetId 
   */
  updateActiveLink: ( targetId ) => {
    tabsSectionScroll.menuLinks.forEach( link => {
      link.closest( '.e-btn' )?.classList.toggle( 'active', link.getAttribute( 'href' ).substring( 1 ) === targetId );
    } );
  },

  /**
   * Handle when observed out of tabs
   */
  handleNoVisibleSections: () => {
    const scrollTop = window.scrollY;
    if ( scrollTop < tabsSectionScroll.sections[0].offsetTop ) {
      tabsSectionScroll.updateActiveLink( tabsSectionScroll.sections[0].id );
    } else if ( scrollTop > tabsSectionScroll.sections[tabsSectionScroll.sections.length - 1].offsetTop ) {
      const last = tabsSectionScroll.menuLinks[tabsSectionScroll.menuLinks.length - 1].closest( '.e-btn' );
      last?.classList.remove( 'active' );
    }
  },
};

document.addEventListener( 'DOMContentLoaded', () => {
  tabsSectionScroll.init();
} );
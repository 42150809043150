export const slideUp = ( target, duration = 250 ) => {
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.boxSizing = 'border-box';
  target.style.height = target.offsetHeight + 'px';
  target.offsetHeight; //NOSONAR
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  window.setTimeout( () => {
    target.style.display = 'none';
    target.style.removeProperty( 'height' );
    target.style.removeProperty( 'padding-top' );
    target.style.removeProperty( 'padding-bottom' );
    target.style.removeProperty( 'margin-top' );
    target.style.removeProperty( 'margin-bottom' );
    target.style.removeProperty( 'overflow' );
    target.style.removeProperty( 'transition-duration' );
    target.style.removeProperty( 'transition-property' );
  }, duration );
};
  
export const slideDown = ( target, duration = 250 ) => {
  target.style.removeProperty( 'display' );
  let display = window.getComputedStyle( target ).display;
  
  if ( display === 'none' ) display = 'block';
  
  target.style.display = display;
  const height = target.offsetHeight;
  target.style.overflow = 'hidden';
  target.style.height = 0;
  target.style.paddingTop = 0;
  target.style.paddingBottom = 0;
  target.style.marginTop = 0;
  target.style.marginBottom = 0;
  target.offsetHeight; //NOSONAR
  target.style.boxSizing = 'border-box';
  target.style.transitionProperty = 'height, margin, padding';
  target.style.transitionDuration = duration + 'ms';
  target.style.height = height + 'px';
  target.style.removeProperty( 'padding-top' );
  target.style.removeProperty( 'padding-bottom' );
  target.style.removeProperty( 'margin-top' );
  target.style.removeProperty( 'margin-bottom' );
  window.setTimeout( () => {
    target.style.removeProperty( 'height' );
    target.style.removeProperty( 'overflow' );
    target.style.removeProperty( 'transition-duration' );
    target.style.removeProperty( 'transition-property' );
  }, duration );
};
  
export const slideToggle = ( target, duration = 250 ) => {
  if ( window.getComputedStyle( target ).display === 'none' ) {
    return slideDown( target, duration );
  }
  return slideUp( target, duration );
};
  
export const addClass = ( el, className = 'active' ) => {
  el.classList.add( className );
};
  
export const removeClass = ( el, className = 'active' ) => {
  el.classList.remove( className );
};
  
export const toggleClass = ( el, className = 'active' ) => {
  el.classList.toggle( className );
};
function addAnimateClass( className ) {
  document.addEventListener( 'DOMContentLoaded', function () {
    const sections = document.querySelectorAll( `.${className}` );
    if ( !sections.length ) return;
  
    const observerOptions = { root: null, rootMargin: '0px', threshold: 0.80 };
    const observerCallback = ( entries, observer ) => {
      entries.forEach( ( entry ) => {
        if ( entry.isIntersecting ) {
          entry.target.classList.add( 'animate-icon' );
          observer.unobserve( entry.target );
        }
      } );
    };
  
    const observer = new IntersectionObserver( observerCallback, observerOptions );
    sections.forEach( ( section ) => observer.observe( section ) );
  } );

}
addAnimateClass( 'e-stn--accordion-with-media-section' );
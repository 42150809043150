/* eslint-disable no-unused-vars */
class SVGAnimation {
  constructor( row ) {
    this.row = row;
    this.svgQueue = [];
    this.isCircular = this.row.classList.contains( 'e-row--animation-repeat' );
    this.interval = 3000;
    this.activeTimeout = null;
    this.observer = new IntersectionObserver( entries => {
      this.observerHandler( entries );
    }, {
      root: null,
      rootMargin: '-15%',
    } );
    this.init();
  }

  fetchSVGs() {
    this.svgQueue = [];
    this.row.querySelectorAll( '.e-svg' ).forEach( svg => {

      const svgatorObject = svg.querySelector( 'object' );
      if ( !svgatorObject ) return;
      const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow?.document;
      
      const svgatorElement = svgatorDocument?.querySelector( 'svg' ) || null;
      if ( svgatorElement ) {
        this.svgQueue.push( svgatorElement );
      }
    } );
  }

  play( index = 0 ) {
    if ( this.svgQueue.length === 0 ) return;
    if ( index >= this.svgQueue.length ) {
      if ( !this.isCircular ) return;
      index = 0;
    }

    if ( !this.svgQueue[index]?.svgatorPlayer ) return;
    const player = this.svgQueue[index].svgatorPlayer;
    player.on( 'end', () => {
      this.activeTimeout = setTimeout( () => {
        this.play( index + 1 );
      }, this.interval );
      player.off( 'end' );
    } );

    player.play();
  }

  terminate() {
    if ( this.activeTimeout ) {
      this.svgQueue.forEach( svg => svg?.svgatorPlayer?.stop() );
      clearTimeout( this.activeTimeout );
      this.activeTimeout = null;
    }
  }

  observerHandler( entries ) {
    if ( entries[0].isIntersecting ) {
      if ( this.isCircular ) {
        this.observer.disconnect();
      }
      this.terminate();
      this.play();
    }
  }

  init() {
    this.fetchSVGs();
    this.observer.observe( this.row );
    this.sliderChangeEvent();
    this.addHoverEvent();
  }

  sliderChangeEvent() {
    const contentSwiper = this.row.querySelector( '.swiper' )?.swiper;
    contentSwiper?.on( 'slideChange', () => {
      this.fetchSVGs();
      this.play();
    } );
  }

  addHoverEvent() {
    this.row.querySelectorAll( '.e-svg' ).forEach( svg => {
      const svgatorObject = svg.querySelector( 'object' );
      const parentLink = svg.closest( 'a' );

      if ( svgatorObject && parentLink ) {
        parentLink.addEventListener( 'mouseenter', () => {
          this.terminate(); // Stop all animations
          const svgatorDocument = svgatorObject.contentDocument || svgatorObject.contentWindow?.document;
          const svgElement = svgatorDocument?.querySelector( 'svg' );
          const player = svgElement?.svgatorPlayer;

          if ( player ) {
            player.play(); // Play animation for hovered SVG once
            player.on( 'end', () => {
              player.stop(); // Stop after playing once
            } );
          }
        } );

        parentLink.addEventListener( 'mouseleave', () => {
          this.play(); // Resume animations for other SVGs
        } );
      }
    } );
  }
}

document.addEventListener( 'readystatechange', () => {
  if ( document.readyState === 'complete' ) {
    document.querySelectorAll( '.e-row--common-svg-animation' ).forEach( row => {
      const svgAnimation = new SVGAnimation( row ); //NOSONAR
    } );
  }
} );
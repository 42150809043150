document.addEventListener( 'DOMContentLoaded', function() {
  const visibleColumns = 6;
  let columns = document.querySelectorAll(
    '.e-stn--leadership-posts .wp-block-b3rg-row .b3rg-posts > .wp-block-b3rg-row > .wp-block-b3rg-column'
  );
  let viewMoreBtn = document.querySelector( '.e-stn--leadership-posts .e-btn' );

  if( columns.length == 0 ) {
    return;
  }

  if( columns.length <= visibleColumns ) {
    viewMoreBtn.style.display = 'none';
  }

  for ( let i = visibleColumns; i < columns.length; i++ ) {
    columns[i].style.display = 'none';
    columns[i].style.opacity = '0';
  }

  if ( viewMoreBtn ) {
    viewMoreBtn.addEventListener( 'click', function( e ) {
      e.preventDefault();
      for ( let i = visibleColumns; i < columns.length; i++ ) {
        columns[i].style.display = 'block';
        columns[i].style.transition = 'opacity 0.5s ease';
        setTimeout( () => {
          columns[i].style.opacity = '1';
        }, 300 );
      }
      columns[visibleColumns].scrollIntoView( { behavior: 'smooth', block: 'end' } );
      this.style.display = 'none';
    } );
  }
} );
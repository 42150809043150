function adjustSwiperSlides() {
  const swiperWrapper = document.querySelector( '.e-stn--collage-slider .swiper-wrapper' );
  if ( !swiperWrapper ) {
    return;
  }
  const slides = swiperWrapper.querySelectorAll( '.e-stn--collage-slider .swiper-slide' );

  if ( window.innerWidth > 991 ) {
    // Append the 2nd child to the 1st child without removing it
    if ( slides[1] && slides[0] ) {
      slides[0].appendChild( slides[1].cloneNode( true ) );
    }

    // Append the 5th child to the last child
    if ( slides[4] && slides.length > 4 ) {
      slides[ slides.length - 1 ].appendChild( slides[4].cloneNode( true ) );
    }
  }
}

document.addEventListener( 'DOMContentLoaded', function () {
  // Run the function on load
  adjustSwiperSlides();
} );
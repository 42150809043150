document.addEventListener( 'DOMContentLoaded', () => {
  let closeButton = document.querySelector( '.b3rg-notification .e-div--notification-close-btn' );
  if ( closeButton ) {
    closeButton.addEventListener( 'click', ( event ) => {
      // Disable the close button
      event.target.disabled = true;
      const notificationId = event.target.getAttribute( 'data-notification-id' );
      const xhr = new XMLHttpRequest();
      xhr.open( 'POST', b3rgScriptData.ajaxurl, true ); // eslint-disable-line no-undef
      xhr.setRequestHeader( 'Content-Type', 'application/x-www-form-urlencoded' );
      xhr.onload = () => {
        if ( xhr.status >= 200 && xhr.status < 400 ) {
          // Removes the notification when cookie creation is done
          event.target.closest( '.b3rg-notification' ).classList.add( 'notification-closed' );
        } else {
          // We reached our target server, but it returned an error
          console.error( xhr.responseText );
        }
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.onerror = () => {
        // There was a connection error of some sort
        console.error( 'Connection error occurred' );
        // Re-enable the close button
        event.target.disabled = false;
      };
      xhr.send( encodeURI( 'action=b3rg_create_notification_cookie&notification_id=' + notificationId ) );
    } );
  }
} );
  
// Check if the element with the class .e-div--header-navigation-with-cta exists
let headerWithCTA = document.querySelector( '.e-div--header-navigation-with-cta' );

if ( headerWithCTA ) {
  // Select the dialog element that has the class wp-block-navigation__responsive-dialog
  let dialog = document.querySelector( '.wp-block-navigation__responsive-dialog' );

  // Check if the dialog element exists and contains a button
  if ( dialog ) {
    let button = dialog.querySelector( 'button' ); // Select the button inside the dialog
    if ( button ) {
      // Select the element with the class wp-block-navigation
      let navigation = document.querySelector( '.wp-block-navigation' );

      // Append the button to the wp-block-navigation element
      if ( navigation ) {
        navigation.appendChild( button );
      }
    }
  }
}
